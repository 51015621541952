
import * as glideRuntime$FpKyFKENY0 from '/app/node_modules/@nuxt/image/dist/runtime/providers/glide'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1600,
    "2xl": 1536
  },
  "presets": {},
  "provider": "glide",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ],
  "quality": 80
}

imageOptions.providers = {
  ['glide']: { provider: glideRuntime$FpKyFKENY0, defaults: {"baseURL":"https://preprod.ssr.icecorp.ru/image/"} }
}
        